<template>
  <div>
    <div class="detailP-comment-box">
      <div class="comment-box">
        <div class="comment-title">댓글 <span class="color-1">{{ total }}</span></div>
        <div class="comment-write-box">
          <profile :profileType="'comment'" :url="getUrl"/>
          <div class="write-input-box">
            <input type="text" class="write-input" @focus="checkLoginStatus" v-model="comment" @keypress.enter="commentSave"
                   :placeholder="this.$msg('comment.placeholder')" maxlength="1000">
            <button ref="commentSaveBtn" class="comment-write-submit" :class="{ 'active' : comment && comment.length > 0 }" @click="commentSave">등록</button>
          </div>
        </div>

        <div class="comment-list">

          <div v-for="item in list" :key="`comment-${item.coIdx}`" class="comment-item">
            <profile :profileType="'comment'" :item="item"/>
            <div class="comment-con">
              <span class="nicname">{{ item.nickName }}</span>
              <span v-if="item.deleteYN">{{ $msg('comment.removed') }}</span>
              <span v-else-if="item.blockYN">{{ $msg('comment.hide') }}</span>
              <span v-else class="comment-content">{{ item.content }}</span>
            </div>
            <div class="comment-info">
              {{ $getDateFormat(item.createDate, 'Y.m.d H:i') }}ㆍ
              <span @click="toggleReplyInput(item.coIdx)">답글</span>
              <span v-if="!item.deleteYN" @click="openReportPopup(item.coIdx)">ㆍ신고</span>
              <span v-if="!item.deleteYN && isMine(item.memIdx)" @click="confirmRemovePopup(item.coIdx, null)">ㆍ삭제</span>
            </div>

            <div v-if="!selectedId && parentIdx === item.coIdx" class="comment-write-reply">
              <div class="comment-write-box">
                <profile :profileType="'comment'" :url="getUrl"/>
                <div class="write-input-box">
                  <input ref="replyInput" type="text" class="write-input" v-model="reply" @keypress.enter="commentSave" v-focus maxlength="1000">
                  <button class="comment-write-submit" :class="{ 'active' : reply && reply.length > 0 }" @click="commentSave">등록</button>
                </div>
              </div>
            </div>

            <div v-for="reply in item.reply" :key="`reply-${reply.coIdx}`" class="comment-item-reply">
              <div class="comment-item ">
                <profile :profileType="'comment'" :item="reply"/>
                <div class="comment-con">
                  <span class="nicname">{{ reply.nickName }}</span>
                  <span v-if="reply.blockYN">{{ $msg('comment.hide') }}</span>
                  <span v-else>{{ reply.content }}</span>
                </div>
                <div class="comment-info">
                  {{ $getDateFormat(item.createDate, 'Y.m.d H:i') }}
                  <span v-if="!reply.deleteYN" @click="openReportPopup(reply.coIdx)">ㆍ신고</span>
                  <span v-if="!reply.deleteYN && isMine(reply.memIdx)" @click="confirmRemovePopup(reply.coIdx, item.coIdx)">ㆍ삭제</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <pagination ref="pagination" :total="listTotal" :size="pageSize" :block="10" v-model="page" @input="getList(0, true)" />
    </div>

    <report-popup v-if="isOpenReportPopup" :coIdx="selectedId" @close="closeReportPopup"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import ReportPopup from '@/components/board/ReportPopup'
export default {
  name: "comment",
  props: ['cateCode', 'count'],
  components: {ReportPopup},
  data() {
    return {
      boIdx: Number(this.$route.params.idx),
      page: 1,
      pageSize: 10,
      total: 0,
      listTotal: 0,
      list: [],
      selectedId: null,
      parentIdx: null,
      comment: null,
      reply: null,
      wordFilter: null,
      isOpenReportPopup: false,
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
      getId: 'session/getId',
      getName: 'session/getName',
      getUrl: 'session/getUrl',
      getWordFilter: 'common/getWordFilter',
    }),
  },
  mounted() {
    setTimeout(() => {
      this.page = Math.ceil(this.count / this.pageSize)
      this.getList(this.page);
    }, 500)
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList(page, isPaging) {
      if (this.page < 1) this.page = 1
      if (page > 0) this.page = page
      const { result, listTotal, total, data } = await this.$api.getCommentList({
        cateCode: this.cateCode,
        boIdx: this.boIdx,
        page: this.page,
        pageSize: this.pageSize,
      })
      if (result === 'success') {
        this.listTotal = parseInt(listTotal)
        this.total = parseInt(total)
        this.list = data
        if (isPaging) this.$refs.commentSaveBtn.focus()
      }
    },
    isMine(id) {
      return id == this.getId ? true : false
    },
    checkLoginStatus() {
      if (!this.isLogin) {
        this.goLogin()
        return false
      }
      return true
    },
    toggleReplyInput(id) {
      if (this.checkLoginStatus()) {
        this.parentIdx = this.parentIdx === id ? null : id
        this.reply = null
      }
    },
    async commentSave() {
      if (this.isLogin) {
        if (this.parentIdx && !this.reply) return false
        if (!this.parentIdx && !this.comment) return false

        let params = {
          cateCode: this.cateCode,
          idx: this.boIdx,
        }
        if (this.parentIdx) {
          params.parentIdx = this.parentIdx
          params.content = this.reply
        }
        else {
          params.parentIdx = 0
          params.content = this.comment
        }

        // 단어필터링
        let wordFilters = this.getWordFilter.split(',')
        const content = params.content
        let banWordList = []
        for (let word of wordFilters) {
          if (content.indexOf(word) > -1) {
            if (banWordList.indexOf(word) < 0) {
              banWordList.push(word)
            }
          }
        }
        if (banWordList.length > 0) {
          this.wordFilter = banWordList.join(',')
          this.wordFilterPopup()
          return false
        }

        const { result, data } = await this.$api.saveComment(params)
        if (result === 'success') {
          if (this.parentIdx) {
            let list = []
            for (let item of this.list) {
              if (item.boIdx === this.parentIdx) {
                if (!item.reply) item.reply= []
                item.reply.push(data)
                list.push(item)
              }
              else {
                list.push(item)
              }
            }
            this.list = list
          }
          this.getList(this.page).then()
          this.comment = null
          this.reply = null
          this.parentIdx = null
        }
      }
    },
    openReportPopup(id) {
      if (this.checkLoginStatus()) {
        this.isOpenReportPopup = true
        this.selectedId = id
      }
    },
    closeReportPopup() {
      this.isOpenReportPopup = false
      this.selectedId = null
    },
    goLogin() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('login.need'),
        okay: 'GO_LOGIN',
        okayCallback: () => {
          this.setMsgPopup()
          localStorage.setItem('loginBeforePage', this.$route.fullPath)
          this.$router.push('/login')
        }
      })
    },
    wordFilterPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('comment.word-filter').replace('{{word}}', this.wordFilter),
        okay: 'WORD_FILTER',
        okayCallback: () => {
          this.setMsgPopup()
        }
      })
    },
    confirmRemovePopup(id, parentIdx) {
      if (this.checkLoginStatus()) {
        this.selectedId = id
        this.parentIdx = parentIdx ? parentIdx : null

        this.setMsgPopup({
          type: 'confirm',
          message: this.$msg('confirm.remove'),
          okay: 'CONFIRM_REMOVE',
          okayCallback: async () => {
            this.setMsgPopup()
            const { result } = await this.$api.removeComment({
              cateCode: this.cateCode,
              coIdx: this.selectedId,
            })
            if (result === 'success') {
              if (this.parentIdx) {
                await this.getList(this.page)
              }
              else {
                const total = Number(this.listTotal) -1
                await this.getList(Math.ceil(total / this.pageSize))
              }

              this.selectedId = null
              this.parentIdx = null
            }
          },
          cancel: 'CLOSE',
          cancelCallback: () => {
            this.setMsgPopup()
            this.selectedId = null
            this.parentIdx = null
          }
        })
      }
    },
  },
}
</script>