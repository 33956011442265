<template>
  <div class="popupBox-wrap">
    <div class="popupPosition"></div>
    <div class="popupBox w-600">
      <div class="popupBox-title center">{{ $msg('report') }}</div>
      <div class="popupBox-body">
        <div class="subtitle-1">{{ $msg('reason') }}<span class="color-1">*</span></div>
        <validateForm ref="form">
          <input-field multiline rows="5" v-model="reason" autofocus :errorMessage="reasonMsg" @keypress.enter="onYesCommentReport"
                       :placeholder="this.$msg('placeholder.report-reason')" maxLength="300"/>
        </validateForm>
      </div>
      <div class="popupBox-bottom">
        <button @click.stop="close" class="w-90 mr-10 btn btn-sm btn-outline color-6">{{ $msg('cancel') }}</button>
        <button @click.stop="save" class="w-90 btn btn-sm fw-bold color-1">{{ $msg('report') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['coIdx'],
  data() {
    return {
      reason: null,
      reasonMsg: null,
    }
  },
  watch: {
    reason(v) {
      this.reason = v.replace(/(?:\r\n|\r|\n)/g,'')
    }
  },
  created() {
  },
  methods: {
    async save() {
      this.reasonMsg = ''
      this.reason = this.reason && this.reason.trim()
      if (!this.reason) {
        this.reasonMsg = this.$msg('required.input')
        return false
      }
      const { result } = await this.$api.reportComment({
        coIdx: this.coIdx,
        memo: this.reason,
      })
      if (result === 'success') this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}

</script>